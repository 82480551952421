import React from "react"
import Solutii from "../../components/Solutii/Solutii"
import Layout from "../../markup/layout/layout"
import { PageBanner } from "../../markup/layout/PageBanner"
import Seo from "../../markup/layout/seo"


function solutii({ slug }) {
  const pageTitle = "Solutii"
  return (
    <Layout>
      <Seo title={pageTitle} />
      <PageBanner
        isHomePage={false}
        pageInfo={{ title: pageTitle, parent: { title: "", slug: "" }, hasParent: false }}
      />
      <Solutii solutionSlug={slug} />
    </Layout>
  )
}

export default solutii
